import * as React from "react";
import { Button, ModalConfirm, Text } from "@nordcloud/gnui";
import { showSuccess } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { ServiceTableItem } from "~/models/services";
import { generateActionSuccessText, useAxios } from "~/tools";

type Props = {
  service: ServiceTableItem;
  refetch: () => Promise<void>;
};

export default function ({ service, refetch }: Props) {
  const [open, setOpen] = React.useState(false);

  const { Axios } = useAxios();
  const { showError } = useShowError();

  const deleteService = async () => {
    try {
      await Axios().delete(`/services/${service.id}`);
      showSuccess(
        generateActionSuccessText(`Service ${service.name}`)()("deleted")()
      );

      return refetch();
    } catch (e) {
      showError(e.response);
    }
  };

  return (
    <>
      <Button
        icon="trash"
        size="sm"
        onClick={() => setOpen(true)}
        severity="low"
      >
        Delete
      </Button>

      <ModalConfirm
        onClose={() => setOpen(false)}
        isOpen={open}
        confirm={deleteService}
        actionLabel="Delete"
        contentLabel={`Delete service ${service.name}`}
      >
        <Text color="warning">
          Are you sure you want to delete service {service.name}?
        </Text>

        {service.numRequests > 0 && (
          <Text color="danger">
            WARNING - All related requests and request categories will also be
            deleted.
          </Text>
        )}
      </ModalConfirm>
    </>
  );
}
