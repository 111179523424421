import * as React from "react";

import { Container } from "react-awesome-styled-grid";
import { Box, Heading, Loader } from "@nordcloud/gnui";
import { QueryPagination, Table } from "~/components";
import { useAPIData } from "~/hooks/useAPIData";
import { ServiceOffering, ServiceTableItem } from "~/models/services";
import { ACTIONS, useAccess } from "~/routing";
import { useQueryState } from "~/tools";
import AddServiceSidebar from "./AddServiceSidebar";
import columns from "./ServicesColumns";

export default function () {
  const [offerings, isLoading, refetch] = useAPIData<ServiceOffering[]>(
    "/service-offerings",
    (data) => data.serviceOfferings,
    []
  );

  const [state] = useQueryState();
  const { page = 0, limit = 20 } = state;

  const { hasAccess: canEdit } = useAccess(ACTIONS.EDIT_SERVICES);

  const tableData: ServiceTableItem[] = [];
  offerings.forEach((offering) => {
    offering.services.forEach((service) => {
      tableData.push({ ...service, offeringName: offering.name });
    });
  });
  const tableDataPage = tableData.slice(page * limit, (page + 1) * limit);

  return (
    <Container>
      <Box css={{ display: "flex", alignItems: "center" }} spacing="spacing04">
        <Heading level={2}>Services</Heading>
        {canEdit && <AddServiceSidebar serviceOfferings={offerings} />}
      </Box>

      <Box spacing="spacing04">
        {isLoading ? (
          <Loader inContent />
        ) : (
          <>
            <Table columns={columns(canEdit, refetch)} data={tableDataPage} />

            <QueryPagination count={tableData.length} />
          </>
        )}
      </Box>
    </Container>
  );
}
