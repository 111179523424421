import * as React from "react";
import { Box, Button, Text } from "@nordcloud/gnui";
import { FlexContainer, Pagination, showSuccess, Table } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { ColumnProps } from "~/components/Tables/types";
import { ServiceCommitmentTableItem } from "~/models/services";
import { ACTIONS, useAccess } from "~/routing";
import { generateActionSuccessText, useAxios } from "~/tools";
import AddCommitmentSidebar from "~/views/services/details/AddCommitmentSidebar";
import { ServiceViewCtx } from "./ServiceViewCtx";

export default function () {
  const { service, refetch } = React.useContext(ServiceViewCtx);

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const { hasAccess: canEdit } = useAccess(ACTIONS.EDIT_SERVICES);

  const { Axios } = useAxios();
  const { showError } = useShowError();

  const detachCommitment = async (commitment: ServiceCommitmentTableItem) => {
    try {
      await Axios().delete(
        `/services/${service.id}/commitments/${commitment.id}`
      );
      const item = `Service commitment ${commitment.name}`;
      showSuccess(generateActionSuccessText(item)()("detached")());

      return refetch();
    } catch (e) {
      showError(e.response);
    }
  };

  const columns: ColumnProps<ServiceCommitmentTableItem>[] = [
    { Header: "Name", accessor: "name" },
    { Header: "Version", accessor: "version" },
  ];

  const commitmentsPage = service.serviceCommitments.slice(
    page * limit,
    (page + 1) * limit
  );

  if (canEdit) {
    columns.push({
      Header: "Actions",
      accessor: "",
      width: "120px",
      Cell: ({ row }) => (
        <Button
          icon="trash"
          size="sm"
          onClick={() => detachCommitment(row.original)}
          severity="low"
        >
          Detach
        </Button>
      ),
    });
  }

  return (
    <Box spacing="spacing04">
      <FlexContainer>
        <Text weight="medium">Service commitments</Text>
        {canEdit && <AddCommitmentSidebar />}
      </FlexContainer>

      <div css={{ marginTop: "1rem" }}>
        <Table
          columns={columns}
          data={commitmentsPage}
          noDataText="No service commitments."
          mb="0"
        />
        {service.serviceCommitments.length > 10 && (
          <Pagination
            count={service.serviceCommitments.length}
            page={page}
            limit={limit}
            setPageAndLimit={(p, l) => {
              setPage(p);
              setLimit(l);
            }}
          />
        )}
      </div>
    </Box>
  );
}
