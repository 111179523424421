import * as React from "react";
import { useContext } from "react";
import { Text } from "@nordcloud/gnui";
import { InlineCustomerRunbook } from "~/components/Applications/ApplicationDetails/InlineCustomerRunbook";
import { Customer } from "~/components/BasicCustomerInfo/types";
import { InlineEditSwitch } from "~/components/InlineEdit/InlineEditSwitch";
import { InlineEditText } from "~/components/InlineEdit/InlineEditText";
import { DetailRow } from "~/components/Utils/DetailRow";
import { confluUrlPattern } from "~/tools/validation/validators";
import { ApplicationViewCtx } from "~/views/customer/applications/ApplicationViewCtx";

type Props = {
  customer: Customer;
};

function RunbookInfo({ customer }: Props) {
  const { application, updateApplication, canEdit } = useContext(
    ApplicationViewCtx
  );

  const runbookLocationTooltip =
    "Must be a Confluence page. Application's runbook will be created as a subpage of this page.";
  const runbookIncludeTooltip =
    "Link to the original runbook. It will be embedded at the end of the automatically generated runbook.";

  return (
    <>
      <Text weight="medium">Runbook</Text>

      <DetailRow title="Runbook location" tooltip={runbookLocationTooltip}>
        <InlineEditText
          name="runbookLocation"
          defaultValue={application.runbookLocation}
          onSubmit={(runbookLocation) => updateApplication({ runbookLocation })}
          canEdit={canEdit}
          validation={confluUrlPattern}
        />
      </DetailRow>

      <DetailRow title="Runbook to include" tooltip={runbookIncludeTooltip}>
        <InlineEditText
          name="existingRunbookInclude"
          defaultValue={application.existingRunbookInclude}
          onSubmit={(val) => updateApplication({ existingRunbookInclude: val })}
          canEdit={canEdit}
          validation={confluUrlPattern}
        />
      </DetailRow>

      <DetailRow title="Runbook accepted by MCE" minWidth={"16rem"}>
        <InlineEditSwitch
          trueLabel="Yes"
          falseLabel="No"
          defaultValue={application.runbookAcceptedByMce}
          onSubmit={(val) => updateApplication({ runbookAcceptedByMce: val })}
          canEdit={canEdit}
        />
      </DetailRow>

      <DetailRow title="Runbook accepted by Customer" minWidth={"16rem"}>
        <InlineEditSwitch
          trueLabel="Yes"
          falseLabel="No"
          defaultValue={application.runbookAcceptedByCustomer}
          onSubmit={(val) =>
            updateApplication({ runbookAcceptedByCustomer: val })
          }
          canEdit={canEdit}
        />
      </DetailRow>

      <DetailRow title="Runbook">
        <InlineCustomerRunbook customer={customer} application={application} />
      </DetailRow>
    </>
  );
}

export { RunbookInfo };
