import * as React from "react";
import { Box, Spacer, Text } from "@nordcloud/gnui";
import { showSuccess } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { InlineEditDropdown } from "~/components/InlineEdit/InlineEditDropdown";
import { InlineEditText } from "~/components/InlineEdit/InlineEditText";
import { DetailRow } from "~/components/Utils/DetailRow";
import { Loading } from "~/components/Utils/Loading";
import { useAPIData } from "~/hooks/useAPIData";
import { ServiceOffering } from "~/models/services";
import { generateActionSuccessText, useAxios } from "~/tools";
import AddReqCategorySidebar from "./AddReqCategorySidebar";
import RequestCategory from "./RequestCategory";
import { ServiceViewCtx } from "./ServiceViewCtx";

type UpdateData = {
  serviceOfferingId?: string;
  name?: string;
};

export default function () {
  const { service, refetch, canEdit } = React.useContext(ServiceViewCtx);

  const [offerings, offeringsLoading] = useAPIData<ServiceOffering[]>(
    "/service-offerings",
    (data) => data.serviceOfferings,
    []
  );

  const { showError } = useShowError();
  const { Axios } = useAxios();

  const update = async (data: UpdateData) => {
    try {
      await Axios().patch(`/services/${service.id}`, data);
      showSuccess(generateActionSuccessText("Service")()("updated")());
    } catch (err) {
      showError(err.response);
    }

    return refetch();
  };

  const offeringOptions = offerings.map((o) => ({
    label: o.name,
    value: o.id,
  }));

  return (
    <Box spacing="spacing04">
      <Text weight="medium">Service Details</Text>
      <DetailRow title="Service offering">
        {offeringsLoading ? (
          <Loading />
        ) : (
          <InlineEditDropdown
            name="serviceOffering"
            options={offeringOptions}
            defaultValue={service.serviceOffering.id}
            onSubmit={(id) => update({ serviceOfferingId: id })}
            canEdit={canEdit}
          />
        )}
      </DetailRow>
      <Spacer height="2px" />

      <DetailRow title="Service name">
        <InlineEditText
          name="name"
          defaultValue={service.name}
          onSubmit={(name) => update({ name })}
          canEdit={canEdit}
        />
      </DetailRow>
      <Spacer height="2px" />

      <DetailRow title="Request categories">
        <div css={{ display: "flex", flexWrap: "wrap", rowGap: "4px" }}>
          {service.requestCategories.map((cat) => (
            <RequestCategory key={cat.id} category={cat} />
          ))}
          {canEdit && <AddReqCategorySidebar />}
        </div>
      </DetailRow>
    </Box>
  );
}
