import * as React from "react";
import { SortingRule } from "react-table";

export default function (
  defaultSortBy: string
): [{ sortBy: string; order: string }, (sorting: SortingRule<any>[]) => void] {
  const [sortParams, setSortParams] = React.useState({
    sortBy: defaultSortBy,
    order: "asc",
  });

  const setSorting = React.useCallback(
    (sorting: SortingRule<any>[]) => {
      const sortBy = sorting[0]?.id || defaultSortBy;
      const order = sorting[0]?.desc ? "desc" : "asc";
      setSortParams({ sortBy, order });
    },
    [defaultSortBy, setSortParams]
  );

  return [sortParams, setSorting];
}
