import Papa from "papaparse";

export type BulkUpdateMode = "customer" | "application" | "environment";

type Option = { label: string; value: string };

const offboardingDateLabel = "Offboarding date";
const goLiveDateLabel = "Go live date";

export const attributeOptions: Record<BulkUpdateMode, Option[]> = {
  customer: [
    { label: "Onboarding date", value: "onboardingDate" },
    { label: offboardingDateLabel, value: "offboardingDate" },
  ],
  application: [
    { label: goLiveDateLabel, value: "goLiveDate" },
    { label: "PagerDuty customer", value: "pdCustomer" },
  ],
  environment: [
    { label: "Status", value: "status" },
    { label: "Soft launch date", value: "softLaunchDate" },
    { label: goLiveDateLabel, value: "goLiveDate" },
    { label: offboardingDateLabel, value: "offboardingDate" },
  ],
};

const modeToIdColumn: Record<BulkUpdateMode, string> = {
  customer: "Overlay ID",
  application: "Application ID",
  environment: "Environment ID",
};

export async function getCSVColumns(csvFile: File): Promise<string[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      preview: 1,
      delimiter: ",",
      error: (err) => reject(err),
      complete: (result) => resolve(result.data[0] as string[]),
    });
  });
}

export function getErrors(
  mode: BulkUpdateMode,
  columns: string[],
  updatedAttrs: string[]
) {
  const missingColumns = [];
  if (columns.length) {
    if (!columns.includes(modeToIdColumn[mode])) {
      missingColumns.push(modeToIdColumn[mode]);
    }

    [
      ["onboardingDate", "(D) Onboarding date"],
      ["offboardingDate", offboardingDateLabel],
      ["goLiveDate", goLiveDateLabel],
      ["pdCustomer", "PagerDuty customer"],
      ["status", "(B) Status"],
      ["softLaunchDate", "(D) Soft launch date"],
      ["goLiveDate", "(E) Go live date"],
      ["offboardingDate", offboardingDateLabel],
    ].forEach(([field, column]) => {
      if (updatedAttrs.includes(field) && !columns.includes(column)) {
        missingColumns.push(column);
      }
    });
  }

  return missingColumns.map(
    (col) => `The given CSV file is missing the '${col}' column`
  );
}
