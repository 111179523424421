import * as React from "react";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { Box, Text } from "@nordcloud/gnui";
import { FlexContainer, Table } from "~/components";
import { EditAssignMemberSidebar } from "~/components/Team/EditAssignMemberSidebar";
import { RolesCell } from "~/components/Team/RolesCell";
import { UnassignTeamMember } from "~/components/Team/UnassignTeamMember";
import { Employee, EmployeeCustomer } from "~/models";

type Props = {
  employee: Employee;
  canEdit: boolean;
  refetch: () => void;
};

export function EmployeeRoles({ employee, canEdit, refetch }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "name",
        Cell: ({ row, value }: Cell<EmployeeCustomer>) => (
          <Link to={`/customers/${row.original.id}`}>{value}</Link>
        ),
      },
      {
        Header: "Roles",
        accessor: "roles",
        disableSortBy: true,
        Cell: ({ value }: Cell<EmployeeCustomer>) => (
          <RolesCell roles={value} />
        ),
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>Actions</div>,
        accessor: "action",
        width: "132px",
        disableSortBy: true,
        Cell: ({ row }: Cell<EmployeeCustomer>) =>
          canEdit ? (
            <FlexContainer>
              <EditAssignMemberSidebar
                customerId={row.original.id}
                employeeId={employee.id}
                employeeRoles={row.original.roles}
                header={row.original.name}
                refetch={refetch}
              />
              <UnassignTeamMember
                customerId={row.original.id}
                employeeId={employee.id}
                confirmMsg={row.original.name}
                deleteCallback={refetch}
              />
            </FlexContainer>
          ) : null,
      },
    ],
    [employee, canEdit, refetch]
  );

  return (
    <Box spacing="spacing04">
      <Text weight="medium">Roles</Text>
      <Table columns={columns} data={employee.customers} mb="0" />
    </Box>
  );
}
