import { createContext } from "react";
import { Service } from "~/models/services";

type Data = {
  service: Service;
  canEdit: boolean;
  refetch: () => Promise<void>;
};

// @ts-ignore
export const ServiceViewCtx = createContext<Data>(null);
