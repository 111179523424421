import * as React from "react";

const DOMAIN =
  process.env.REACT_APP_ENV === "production" ? "nordcloud" : "dev-nordcloud";

const PD_URL = `https://${DOMAIN}.pagerduty.com`;

const baseUrlMap = {
  service: `${PD_URL}/service-directory/`,
  escalationPolicy: `${PD_URL}/escalation_policies#`,
};

type Props = {
  type: "service" | "escalationPolicy";
  id?: string;
  children: React.ReactNode;
};

export function PagerDutyLink({ type, id, children }: Props) {
  if (!id) {
    return <>{children}</>;
  }

  return (
    <a href={`${baseUrlMap[type]}${id}`} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
