import * as React from "react";
import { Button, Modal, Spacer } from "@nordcloud/gnui";
import { FlexContainer } from "~/components";

type Props = {
  errCode: string;
  close: () => void;
};

function ErrorModal({ errCode, close }: Props) {
  let introMsg;
  let accessType;
  if (errCode === "INVALID_RUNBOOK_LOCATION_LINK") {
    introMsg = "The given runbook location link is invalid";
    accessType = "edit permissions";
  } else if (errCode === "INVALID_RUNBOOK_INCLUDE_LINK") {
    introMsg = "The given included runbook link is invalid";
    accessType = "read permissions";
  }

  return (
    <Modal
      contentLabel="Updating application failed"
      isOpen={errCode !== ""}
      onClose={close}
      actions={[]}
    >
      <p>
        {introMsg}. Either the page does not exist or Overlay does not have
        access to it.
      </p>
      <Spacer height="1rem" />

      <p>
        To give Overlay access to this page, the Confluence user &quot;Runbook
        Automation&quot; must be given {accessType} to the related Confluence
        space. To add permissions in Confluence, select &quot;Space
        settings&quot; from the sidebar on the left, then click &quot;Edit
        Permissions&quot; in the &quot;Individual Users&quot; section. Now you
        should be able to search for &quot;Runbook Automation&quot; in the text
        box and add permissions for this user.
      </p>
      <Spacer height="1rem" />

      <p>
        If you cannot edit permissions for the given Confluence space, you need
        to contact the space administrator or create a request for the ITSM
        team.
      </p>

      <Spacer height="1rem" />
      <FlexContainer justify="center">
        <Button onClick={close} type="button" color="primary">
          OK
        </Button>
      </FlexContainer>
    </Modal>
  );
}

export { ErrorModal };
