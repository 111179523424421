export const confluUrlPattern = {
  pattern: {
    value: /^https:\/\/nordcloud.atlassian.net\/\S+$/,
    message: "Please provide a valid Confluence URL",
  },
};

export const jiraIssueKeyPattern = {
  pattern: {
    value: /^[A-Z]{2,5}-\d{1,9}$/,
    message: "Please provide a valid Jira issue key",
  },
};

export const urlPattern = {
  pattern: {
    value: /^https:\/\/\S+$/,
    message: "Please provide a valid URL",
  },
};

export const maxLength = (length: number) => ({
  maxLength: {
    value: length,
    message: `Maximum length is ${length} characters`,
  },
});
