import * as React from "react";
import { Loader, Message, Spacer } from "@nordcloud/gnui";
import { SidebarMultiSelect } from "~/components/SidebarForm/inputs/SidebarMultiSelect";
import { useAPIData } from "~/hooks/useAPIData";
import { ExternalRole } from "~/models";

const contactTypes = [
  "Primary Contact",
  "Secondary Contact",
  "Business Escalation Contact",
  "Security Contact",
  "Validated Business Contact",
  "Validated Technical Contact",
  "Major Incident Escalation Contact",
  "System Outage Escalation Point",
  "Third Party Contact",
];

type Props = {
  currentRoles?: ExternalRole[];
};

function ContactRolesSelect({ currentRoles }: Props) {
  const [roles, rolesLoading] = useAPIData<ExternalRole[]>(
    "/external/roles",
    (data) => data.externalRoles,
    []
  );

  const roleOpts = roles
    .filter((role) => contactTypes.includes(role.name))
    .map((role) => ({ label: role.name, value: role.id }));

  const deprecatedRoles = (currentRoles || [])
    .filter((role) => !contactTypes.includes(role.name))
    .map((role) => role.name);

  const defaultValue = React.useMemo(
    () =>
      (currentRoles || [])
        .filter((role) => contactTypes.includes(role.name))
        .map((role) => role.id),
    [currentRoles]
  );

  if (rolesLoading) {
    return (
      <>
        <Loader inContent />
        <Spacer height="1rem" />
      </>
    );
  }

  return (
    <>
      <SidebarMultiSelect
        name="externalRoleIds"
        label="Contact type"
        options={roleOpts}
        defaultValue={defaultValue}
      />
      {deprecatedRoles.length > 0 && (
        <Message status="notification" css={{ marginBottom: "1rem" }}>
          Currently assigned contact type(s): {deprecatedRoles.join(", ")} are
          not supported anymore. They will be removed after this form is
          submitted.
        </Message>
      )}
    </>
  );
}

export { ContactRolesSelect };
