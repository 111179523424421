import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ENVIRONMENT } from "~/components/config";
import { LoaderWrap } from "~/components/LoaderWrap/LoaderWrap";
import { DetailRow } from "~/components/Utils/DetailRow";
import { useAPIData } from "~/hooks/useAPIData";

const mcrpDomainMap: Record<string, string> = {
  development: "portal.dev.mc.nordcloud.com",
  staging: "portal.stg.mc.nordcloud.com",
  production: "mc.nordcloud.com",
};

const InfoWrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 15px;
  color: var(--text-text02);
`;

type CPDData = {
  configured: boolean;
  owners: string[];
};

export default function () {
  const { customerId } = useParams<{ customerId: string }>();

  const [data, loading] = useAPIData<CPDData | null>(
    `/customers/${customerId}/cpd`,
    (respData) => respData,
    null
  );

  const portalUrl = `https://${mcrpDomainMap[ENVIRONMENT]}/confluence-duplicator`;

  const owners = (data?.owners || []).join(", ");

  return (
    <LoaderWrap loading={loading}>
      <InfoWrapper>
        The following section is automatically updated based on changes made in{" "}
        <a href={portalUrl} target="_blank" rel="noreferrer">
          MC Request Portal
        </a>
      </InfoWrapper>

      <DetailRow title="Configured">
        {data?.configured ? "Yes" : "No"}
      </DetailRow>

      <DetailRow title="Owners">{owners || "-"}</DetailRow>
    </LoaderWrap>
  );
}
