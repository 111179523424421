import * as React from "react";
import { MdFeedback } from "react-icons/md";
import styled from "styled-components";
import { Box, Heading, Text } from "@nordcloud/gnui";
import { Backdrop } from "~/components/Styled/Backdrop";
import { useClickOutside } from "~/tools";

const FeedbackButton = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const BoxWrapper = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 5.3rem;
  z-index: 5;
  width: 300px;
`;

export function HelpAndFeedback() {
  const [showBox, setShowBox] = React.useState(false);
  const wrapperRef = React.useRef(null);

  const docsUrl =
    "https://nordcloud.atlassian.net/wiki/spaces/CMDB/pages/2862383156";
  const portalUrl =
    "https://mc.nordcloud.com/mc-automated-solutions-request?solution=Overlay";

  useClickOutside(wrapperRef, () => {
    setShowBox(false);
  });

  return (
    <>
      <FeedbackButton onClick={() => setShowBox(true)}>
        <Box>
          <MdFeedback
            style={{ display: "block", width: "1.5rem", height: "1.5rem" }}
          />
        </Box>
      </FeedbackButton>

      {showBox && <Backdrop />}
      {showBox && (
        <BoxWrapper ref={wrapperRef}>
          <Box>
            <Heading level={3}>Help and feedback</Heading>

            <Text mt={3}>
              Check{" "}
              <a href={docsUrl} target="_blank" rel="noreferrer">
                Overlay documentation
              </a>{" "}
              for instructions on how to use the website.
            </Text>

            <Text mb={0}>
              You can also leave feedback and request changes using{" "}
              <a href={portalUrl} target="_blank" rel="noreferrer">
                the form in MC Request Portal
              </a>
              .
            </Text>
          </Box>
        </BoxWrapper>
      )}
    </>
  );
}
