import { createContext } from "react";
import { Application, UpdateApplicationData } from "~/models";

type Data = {
  application: Application;
  updateApplication: (data: UpdateApplicationData) => Promise<void>;
  canEdit: boolean;
};

// @ts-ignore
export const ApplicationViewCtx = createContext<Data>(null);
