import * as React from "react";
import { Row, Col } from "react-awesome-styled-grid";
import { FlexContainer } from "~/components";

type Props = {
  label: string;
  children: React.ReactNode;
};

export default function ({ label, children }: Props) {
  return (
    <Row css={{ marginBottom: "12px" }}>
      <Col xs={4} sm={3} md={3} lg={3}>
        <FlexContainer
          align="center"
          css={{ height: "100%", maxHeight: "42px" }}
        >
          <label>{label}</label>
        </FlexContainer>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
}
