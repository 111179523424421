import * as React from "react";

import { Container } from "react-awesome-styled-grid";
import { Box, Heading } from "@nordcloud/gnui";

function ReportPage() {
  const link =
    "https://app.powerbi.com/reportEmbed?reportId=e9cc0e99-d850-4773-8179-66c7a406cbf0&appId=d0c5998b-9c3c-4134-bd94-c8805d5be600&autoAuth=true&ctid=f8407946-d2c1-4fcc-ae95-63723ace3665";

  return (
    <Container>
      <Box>
        <Heading level={2}>Reporting</Heading>
      </Box>
      <Box
        spacing="spacing04"
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 60px)",
        }}
      >
        <iframe
          title="Customers CSM Report"
          style={{ width: "90%", height: "100%", border: "none" }}
          src={link}
        />
      </Box>
    </Container>
  );
}

export { ReportPage };
