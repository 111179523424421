import * as React from "react";
import { v4 } from "uuid";

export const fuseOptions = {
  minMatchCharLength: 2,
  threshold: 0.1,
  ignoreLocation: true,
};

export function highlightQueryText(text: string, filterValue: string) {
  const escaped = filterValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const reg = new RegExp(`(${escaped})`, "gi");

  const textParts = text?.split(reg);
  const textPartsAddId = textParts?.map((textElement) => ({
    id: v4(),
    element: textElement,
  }));

  return textPartsAddId?.map((part) => {
    return part.element.match(reg) ? (
      <b key={part.id}>{part.element}</b>
    ) : (
      part.element
    );
  });
}
