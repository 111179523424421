import * as React from "react";
import styled from "styled-components";
import { ColumnProps } from "~/components/Tables/types";
import { Request } from "~/models";
import { displayYesOrNo } from "~/tools";

const Center = styled.div`
  text-align: center;
`;

const NameWrapper = styled.div`
  max-width: 700px;
  overflow-wrap: break-word;
`;

export function reqTableColumns<T extends Request>(
  categoryAccessor: string
): ColumnProps<T>[] {
  return [
    { Header: "Category", accessor: categoryAccessor },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <NameWrapper>{value}</NameWrapper>,
    },
    {
      Header: "Included in base fee",
      accessor: "includedInBaseFee",
      center: true,
      Cell: ({ value }) => <Center>{displayYesOrNo(value)}</Center>,
    },
    {
      Header: "Extra chargeable",
      accessor: "extraChargeable",
      center: true,
      Cell: ({ value }) => <Center>{displayYesOrNo(value)}</Center>,
    },
    {
      Header: "Valid in IaaS",
      accessor: "validInIaaS",
      center: true,
      Cell: ({ value }) => <Center>{displayYesOrNo(value)}</Center>,
    },
    {
      Header: "Valid in PaaS",
      accessor: "validInPaaS",
      center: true,
      Cell: ({ value }) => <Center>{displayYesOrNo(value)}</Center>,
    },
  ];
}
