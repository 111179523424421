import * as React from "react";
import { Box, Text } from "@nordcloud/gnui";
import { FlexContainer, Pagination, Table } from "~/components";
import { reqTableColumns } from "~/components/Requests/tableColumns";
import { RequestTableItem } from "~/models/services";
import { ServiceViewCtx } from "~/views/services/details/ServiceViewCtx";
import AddRequestButton from "./AddRequestButton";
import DeleteRequest from "./DeleteRequest";
import EditRequestButton from "./EditRequestButton";

export default function () {
  const { service, canEdit } = React.useContext(ServiceViewCtx);

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const requests: RequestTableItem[] = [];
  service.requestCategories.forEach((reqCategory) => {
    reqCategory.requests.forEach((req) => {
      requests.push({
        ...req,
        categoryId: reqCategory.id,
        categoryName: reqCategory.name,
      });
    });
  });

  const requestsPage = requests.slice(page * limit, (page + 1) * limit);

  const columns = reqTableColumns<RequestTableItem>("categoryName");

  if (canEdit) {
    columns.push({
      Header: "Actions",
      accessor: "",
      width: "120px",
      Cell: ({ row }) => (
        <FlexContainer>
          <EditRequestButton request={row.original} />
          <DeleteRequest request={row.original} />
        </FlexContainer>
      ),
    });
  }

  return (
    <Box spacing="spacing04">
      <FlexContainer>
        <Text weight="medium">Requests</Text>
        {canEdit && <AddRequestButton />}
      </FlexContainer>

      <div css={{ marginTop: "1rem" }}>
        <Table
          columns={columns}
          data={requestsPage}
          noDataText="No requests."
          mb="0"
        />
        {requests.length > 10 && (
          <Pagination
            count={requests.length}
            page={page}
            limit={limit}
            setPageAndLimit={(p, l) => {
              setPage(p);
              setLimit(l);
            }}
          />
        )}
      </div>
    </Box>
  );
}
