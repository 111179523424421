import * as React from "react";
import { Container } from "react-awesome-styled-grid";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Heading, Loader, Spacer } from "@nordcloud/gnui";
import { useAPIData } from "~/hooks/useAPIData";

type BulkUpdateInfo = {
  id: string;
  status: "IN_PROGRESS" | "DONE" | "FAILED";
  statusReason: string | null;
};

export default function () {
  const [refreshInterval, setRefreshInterval] = React.useState(-1);

  const { updateId } = useParams<{ updateId: string }>();

  const [updateInfo, , refresh] = useAPIData<BulkUpdateInfo | null>(
    `/bulk-update/${updateId}`,
    (data) => data,
    null
  );

  React.useEffect(() => {
    const interval = setInterval(refresh, 5000);
    setRefreshInterval(interval);

    return () => clearInterval(refreshInterval);
  }, []);

  React.useEffect(() => {
    if (updateInfo?.status === "DONE") {
      clearInterval(refreshInterval);
    }
  }, [refreshInterval, updateInfo]);

  if (!updateInfo) {
    return <Loader inContent />;
  }

  return (
    <Container css={{ width: "1000px" }}>
      <Box spacing="spacing04">
        <Heading level={2}>Bulk update info</Heading>
        <Spacer height="1rem" />

        {updateInfo.status === "IN_PROGRESS" && (
          <Box border="notification">
            Bulk update is currently in progress. This process can take a couple
            of minutes. This page will automatically refresh when the update
            finishes.
            <Spacer height="1rem" />
            Thank you for your patience!
          </Box>
        )}

        {updateInfo.status === "DONE" && (
          <>
            <Box border="success">
              Bulk update has been performed successfully.
            </Box>
            <Spacer height="1rem" />
            <Link to="/bulk-update">
              <Button>Another bulk update</Button>
            </Link>
          </>
        )}

        {updateInfo.status === "FAILED" && (
          <Box border="danger">
            Bulk update has failed. Reason:
            <Spacer height="1rem" />
            {updateInfo.statusReason}
          </Box>
        )}
      </Box>
    </Container>
  );
}
