import * as React from "react";
import { ModalConfirm, Text } from "@nordcloud/gnui";
import { FlexContainer, showSuccess } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { ClearButton } from "~/components/InlineEdit/ClearButton";
import { RequestCategory } from "~/models/services";
import { generateActionSuccessText, useAxios } from "~/tools";
import { ServiceViewCtx } from "./ServiceViewCtx";

type Props = {
  category: RequestCategory;
};

export default function ({ category }: Props) {
  const { refetch, canEdit } = React.useContext(ServiceViewCtx);

  const [open, setOpen] = React.useState(false);

  const { Axios } = useAxios();
  const { showError } = useShowError();

  const deleteCategory = async () => {
    try {
      await Axios().delete(`/request-categories/${category.id}`);
      showSuccess(
        generateActionSuccessText(`Request category ${category.name}`)()(
          "deleted"
        )()
      );
      setOpen(false);

      return refetch();
    } catch (e) {
      showError(e.response);
    }
  };

  return (
    <FlexContainer align="center" style={{ marginRight: "0.75rem" }}>
      {category.name}

      {canEdit && (
        <>
          <ClearButton
            onClick={async () => setOpen(true)}
            isLoading={false}
            margin="4px"
          />

          <ModalConfirm
            onClose={() => setOpen(false)}
            isOpen={open}
            confirm={deleteCategory}
            actionLabel="Delete"
            contentLabel={`Delete request category ${category.name}`}
          >
            <Text color="warning">
              Are you sure you want to delete request category {category.name}?
            </Text>

            {category.requests.length > 0 && (
              <Text color="danger">
                WARNING - All related requests will also be deleted.
              </Text>
            )}
          </ModalConfirm>
        </>
      )}
    </FlexContainer>
  );
}
