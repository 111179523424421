import * as React from "react";
import { Box, Text } from "@nordcloud/gnui";
import { showSuccess } from "~/components";
import { AmazonConnect } from "~/components/BasicCustomerInfo/types";
import { useShowError } from "~/components/Error/useShowError";
import { InlineEditDropdown } from "~/components/InlineEdit/InlineEditDropdown";
import { InlineEditSwitch } from "~/components/InlineEdit/InlineEditSwitch";
import { InlineEditText } from "~/components/InlineEdit/InlineEditText";
import { LoaderWrap } from "~/components/LoaderWrap/LoaderWrap";
import { DetailRow } from "~/components/Utils/DetailRow";
import { countryList } from "~/constants";
import { useCustomer } from "~/context/User/Customer";
import { useUserConsumer } from "~/context/User/User";
import { isStandardUser } from "~/routing";
import { generateActionSuccessText, useAxios } from "~/tools";
import { isValidPhone } from "~/tools/validation/checkPhone";

function AmazonConnectDetails() {
  const { customer, fetchCustomer, isCustomerLoading } = useCustomer();

  const { role } = useUserConsumer();
  const canEdit = isStandardUser(role);

  const { showError } = useShowError();
  const { Axios } = useAxios();
  const onSubmit = async (
    key: keyof AmazonConnect,
    value: string | boolean
  ) => {
    try {
      const defaults = { active: false, ...customer.amazonConnect };
      const updateData = {
        ...customer,
        amazonConnect: { ...defaults, [key]: value || null },
      };
      await Axios().put(`/customers/${customer.id}`, updateData);
      showSuccess(
        generateActionSuccessText(`Customer ${customer.name}`)()("updated")()
      );
    } catch (e) {
      showError(e.response);
    }

    return fetchCustomer();
  };

  return (
    <Box spacing="spacing04">
      <LoaderWrap loading={isCustomerLoading}>
        <Text weight="medium">Amazon Connect</Text>

        <DetailRow title="Phone number">
          <InlineEditText
            name="phone"
            defaultValue={customer.amazonConnect?.phone}
            onSubmit={(phone) => onSubmit("phone", phone)}
            validation={{ validate: isValidPhone }}
            canEdit={canEdit}
          />
        </DetailRow>

        <DetailRow title="Country">
          <InlineEditDropdown
            name="country"
            options={countryList.map((c) => c.label)}
            defaultValue={customer.amazonConnect?.country || ""}
            clearable
            onSubmit={(country) => onSubmit("country", country)}
            canEdit={canEdit}
          />
        </DetailRow>

        <DetailRow title="Active">
          <InlineEditSwitch
            defaultValue={customer.amazonConnect?.active || false}
            onSubmit={(active) => onSubmit("active", active)}
            canEdit={canEdit}
          />
        </DetailRow>
      </LoaderWrap>
    </Box>
  );
}

export { AmazonConnectDetails };
