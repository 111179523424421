import * as React from "react";
import { Container } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { Loader } from "@nordcloud/gnui";
import { Breadcrumbs } from "~/components";
import { useAPIData } from "~/hooks/useAPIData";
import { Service } from "~/models/services";
import { ACTIONS, useAccess } from "~/routing";
import Commitments from "./Commitments";
import Requests from "./requests/Requests";
import ServiceDetails from "./ServiceDetails";
import { ServiceViewCtx } from "./ServiceViewCtx";

export default function () {
  const state = useParams<{ serviceId: string }>();
  const { serviceId } = state;

  const [service, , refetch] = useAPIData<Service | null>(
    `/services/${serviceId}`,
    (data) => data,
    null
  );

  const { hasAccess: canEdit } = useAccess(ACTIONS.EDIT_SERVICES);

  if (service === null) {
    return (
      <Container>
        <Loader inContent />
      </Container>
    );
  }

  const breadcrumbs = [{ value: service.id, label: service.name }];

  return (
    <ServiceViewCtx.Provider value={{ service, canEdit, refetch }}>
      <Container>
        <Breadcrumbs title={service.name} labels={breadcrumbs} />

        <ServiceDetails />
        <Requests />
        <Commitments />
      </Container>
    </ServiceViewCtx.Provider>
  );
}
