import { Link } from "~/components/BasicCustomerInfo/types";
import { isValidUrlSchema, itHasNotWhitespace } from "~/tools";

export const validateLink = (link: Link): string[] => {
  const errors = [];
  if (!link.name) {
    errors.push("name"); // eslint-disable-line fp/no-mutating-methods
  }
  if (
    !itHasNotWhitespace(link.url) ||
    link.url.length >= 2048 ||
    link.url.length === 0 ||
    !isValidUrlSchema.safeParse(link.url).success
  ) {
    errors.push("url"); // eslint-disable-line fp/no-mutating-methods
  }

  return errors;
};
