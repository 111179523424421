import * as React from "react";
import { ChangeEvent } from "react";
import { Container } from "react-awesome-styled-grid";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Heading, Select, Spacer, Switch } from "@nordcloud/gnui";
import { FlexContainer } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { OvDropdown } from "~/components/Forms/OvDropdown";
import { ROUTES } from "~/routing";
import { useAxios } from "~/tools";
import {
  getCSVColumns,
  getErrors,
  BulkUpdateMode,
  attributeOptions,
} from "~/views/bulkUpdate/bulkUpdate";
import FormRow from "~/views/bulkUpdate/FormRow";

type ModeOption = { value: BulkUpdateMode; label: string };

const modeOptions: ModeOption[] = [
  { value: "customer", label: "Update customers" },
  // Remaining options disabled until reports are updated (RMC-1600)
  // { value: "application", label: "Update applications" },
  // { value: "environment", label: "Update environments" },
];

export default function () {
  const { handleSubmit, register, setValue, watch } = useForm();
  const [columns, setColumns] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);

  const { Axios } = useAxios();
  const { showError } = useShowError();
  const history = useHistory();

  React.useEffect(() => {
    register({ name: "mode" });
    register({ name: "csvData" });
    register({ name: "overwrite", value: false });
    register({ name: "updatedAttributes" });
  }, [register]);

  const watcher = watch();
  const mode: BulkUpdateMode = watcher.mode;
  const attrOptions = attributeOptions[mode];

  const updateMode = (value: BulkUpdateMode) => {
    setValue("mode", value);
    setValue("updatedAttributes", []);
  };

  const loadCsv = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      return;
    }

    const file = event.target.files[0];
    const parsedColumns = await getCSVColumns(file);
    if (parsedColumns.length >= 2) {
      setColumns(parsedColumns);
      setValue("csvData", await file.text());
    } else {
      toast.error("The selected CSV file is invalid");
    }
  };

  const submit = async (values: any) => {
    setLoading(true);
    try {
      const { data } = await Axios().post("/bulk-update", values);
      history.push(`${ROUTES.bulkUpdate}/${data.id}`);
    } catch (e) {
      showError(e.response);
    }
    setLoading(false);
  };

  const errors = getErrors(watcher.mode, columns, watcher.updatedAttributes);

  const showAttrs = !!(watcher.mode && columns.length);
  const submitDisabled =
    !(watcher.mode && watcher.csvData && watcher.updatedAttributes.length) ||
    errors.length > 0;

  return (
    <Container css={{ width: "1000px" }}>
      <Box spacing="spacing04">
        <Heading css={{ minHeight: "2.25rem" }} level={2} mb={3}>
          Bulk update
        </Heading>

        <form onSubmit={handleSubmit(submit)}>
          <FormRow label="Mode">
            <OvDropdown
              name="mode"
              onChange={updateMode}
              value={watcher.mode}
              options={modeOptions}
              placeholder="Mode"
            />
          </FormRow>

          <FormRow label="CSV file">
            <input type="file" onChange={loadCsv} />
          </FormRow>

          {showAttrs && (
            <>
              <FormRow label="Updated attributes">
                <Select
                  isMulti
                  // @ts-expect-error there is a bug with type generation in GNUI
                  onChange={(newValue: any[]) => {
                    const mapped = newValue.map((v) => v.value);
                    setValue("updatedAttributes", mapped);
                  }}
                  value={attrOptions.filter((opt) =>
                    (watcher.updatedAttributes || []).includes(opt.value)
                  )}
                  options={attrOptions}
                />
              </FormRow>

              <FormRow label="">
                <Switch
                  position="right"
                  labelText="Overwrite existing values"
                  checked={watcher.overwrite}
                  onChange={(event) =>
                    setValue("overwrite", event.target.checked)
                  }
                />
              </FormRow>
            </>
          )}

          {errors.length > 0 && (
            <Box border="danger" color="red" mb={3}>
              The following errors were found:
              <ul css={{ listStyle: "initial", marginLeft: "16px" }}>
                {errors.map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </Box>
          )}

          <Spacer height="16px" />
          <FlexContainer justify="center">
            <Button
              type="submit"
              disabled={submitDisabled}
              initialState={loading ? "loading" : ""}
            >
              Submit bulk update
            </Button>
          </FlexContainer>
        </form>
      </Box>
    </Container>
  );
}
