import * as React from "react";
import styled from "styled-components";
import { FlexContainer } from "~/components";
import { Role } from "~/components/Team/types";

const RoleWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  line-height: 1.1;
`;

type Props = {
  roles: Role[];
};

export function RolesCell({ roles }: Props) {
  return (
    <FlexContainer justify="center" direction="column" align="center">
      {roles.map((r: Role) => (
        <RoleWrapper key={r.id}>{r?.name}</RoleWrapper>
      ))}
    </FlexContainer>
  );
}
