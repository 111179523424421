import * as React from "react";
import styles from "~/assets/styles/DetailsRow.module.css";
import { FlexContainer } from "~/components";
import { DetailsTitle } from "~/components/Styled/Common";
import { TooltipTitle } from "~/components/Styled/TooltipTitle";

type Props = {
  title: string;
  tooltip?: string;
  minWidth?: string;
  children: React.ReactNode;
  alignItems?: "center" | "start";
};

function DetailRow({
  title,
  tooltip,
  minWidth,
  children,
  alignItems = "center",
}: Props) {
  const titleCss = minWidth ? { minWidth } : {};

  return (
    <FlexContainer className={styles.detailsRow} css={{ alignItems }}>
      {tooltip ? (
        <TooltipTitle
          title={`${title}:`}
          caption={tooltip}
          minWidth={minWidth}
        />
      ) : (
        <DetailsTitle css={titleCss}>{title}:</DetailsTitle>
      )}

      {children}
    </FlexContainer>
  );
}

export { DetailRow };
