import * as React from "react";
import { Button, ModalConfirm, Text } from "@nordcloud/gnui";
import { showSuccess } from "~/components";
import { useShowError } from "~/components/Error/useShowError";
import { Request } from "~/models/services";
import { generateActionSuccessText, useAxios } from "~/tools";
import { ServiceViewCtx } from "~/views/services/details/ServiceViewCtx";

type Props = {
  request: Request;
};

export default function ({ request }: Props) {
  const { refetch } = React.useContext(ServiceViewCtx);

  const [open, setOpen] = React.useState(false);

  const { Axios } = useAxios();
  const { showError } = useShowError();

  const deleteRequest = async () => {
    try {
      await Axios().delete(`/requests/${request.id}`);
      showSuccess(
        generateActionSuccessText(`Request ${request.name}`)()("deleted")()
      );

      return refetch();
    } catch (e) {
      showError(e.response);
    }
  };

  return (
    <>
      <Button
        icon="trash"
        size="sm"
        onClick={() => setOpen(true)}
        severity="low"
      >
        Delete
      </Button>

      <ModalConfirm
        onClose={() => setOpen(false)}
        isOpen={open}
        confirm={deleteRequest}
        actionLabel="Delete"
        contentLabel={`Delete request ${request.name}`}
      >
        <Text color="warning">
          Are you sure you want to delete request {request.name}?
        </Text>
      </ModalConfirm>
    </>
  );
}
