import * as React from "react";
import { Container } from "react-awesome-styled-grid";
import styled from "styled-components";
import { Box, Heading, Loader } from "@nordcloud/gnui";
import { QueryPagination, Table } from "~/components";
import { ColumnProps } from "~/components/Tables/types";
import { useAPIData } from "~/hooks/useAPIData";
import { ServiceOffering } from "~/models/services";
import { ACTIONS, useAccess } from "~/routing";
import { useQueryState } from "~/tools";
import AddServiceOfferingSidebar from "./AddServiceOfferingSidebar";
import DeleteServiceOffering from "./DeleteServiceOffering";

const Center = styled.div`
  text-align: center;
`;

export default function () {
  const [offerings, isLoading, refetch] = useAPIData<ServiceOffering[]>(
    "/service-offerings",
    (data) => data.serviceOfferings,
    []
  );

  const [state] = useQueryState();
  const { page = 0, limit = 20 } = state;

  const { hasAccess: canEdit } = useAccess(ACTIONS.EDIT_SERVICES);

  const tableDataPage = offerings.slice(page * limit, (page + 1) * limit);

  const columns: ColumnProps<ServiceOffering>[] = [
    { Header: "Name", accessor: "name" },
    {
      Header: "Number of services",
      accessor: "services",
      center: true,
      Cell: ({ value }) => <Center>{value.length}</Center>,
    },
  ];

  if (canEdit) {
    columns.push({
      Header: "Actions",
      accessor: "",
      width: "60px",
      Cell: ({ row }) => (
        <DeleteServiceOffering offering={row.original} refetch={refetch} />
      ),
    });
  }

  return (
    <Container>
      <Box css={{ display: "flex", alignItems: "center" }} spacing="spacing04">
        <Heading level={2}>Service offerings</Heading>
        {canEdit && <AddServiceOfferingSidebar refetch={refetch} />}
      </Box>

      <Box spacing="spacing04">
        {isLoading ? (
          <Loader inContent />
        ) : (
          <>
            <Table columns={columns} data={tableDataPage} />
            <QueryPagination count={offerings.length} />
          </>
        )}
      </Box>
    </Container>
  );
}
