import { PDService } from "~/models/pagerDuty";

export function displayPDIntegrations(serviceDetails: PDService) {
  const integrations = Object.values(
    serviceDetails.integrations || {}
  ).map((i) =>
    i.vendor
      .replace("events_api_v2_inbound_integration", "Events API v2")
      .trim()
  );

  return integrations.join(", ");
}
