import * as React from "react";
import { Button } from "@nordcloud/gnui";
import { Customer } from "~/components/BasicCustomerInfo/types";
import { InlineEditText } from "~/components/InlineEdit/InlineEditText";
import { DetailRow } from "~/components/Utils/DetailRow";

const emailValidator = {
  pattern: {
    value: /\S+@\w+\.\w+/,
    message: "Not a valid email",
  },
};

type Props = {
  field: "raiseAlarmEmail" | "serviceRequestEmail";
  title: string;
  customer: Customer;
  onSubmit: (values: Customer) => Promise<void>;
  canEdit: boolean;
};

export default function ({ field, title, customer, onSubmit, canEdit }: Props) {
  const [naLoading, setNaLoading] = React.useState(false);
  const [showNaButton, setShowNaButton] = React.useState(true);

  const submit = (value: string) =>
    onSubmit({
      ...customer,
      info: { ...customer.info, [field]: value },
    });

  return (
    <DetailRow title={title}>
      <InlineEditText
        name={field}
        defaultValue={customer.info[field]}
        onSubmit={async (val) => {
          setShowNaButton(false);
          await submit(val);
          setShowNaButton(true);
        }}
        canEdit={canEdit}
        validation={emailValidator}
      />

      {canEdit && showNaButton && (
        <Button
          initialState={naLoading ? "loading" : ""}
          severity="low"
          size="sm"
          onClick={async () => {
            setNaLoading(true);
            await submit("Not applicable");
            setNaLoading(false);
          }}
        >
          Not applicable
        </Button>
      )}
    </DetailRow>
  );
}
