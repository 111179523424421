import * as React from "react";
import styled from "styled-components";
import { Text } from "@nordcloud/gnui";
import { Table } from "~/components";
import {
  Customer,
  DatadogOrganization,
} from "~/components/BasicCustomerInfo/types";
import { ColumnProps } from "~/components/Tables/types";
import { DetailRow } from "~/components/Utils/DetailRow";
import { useAPIData } from "~/hooks/useAPIData";
import DatadogOrgsSelect from "./DatadogOrgsSelect";

const TableBorder = styled.div`
  margin-top: 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
`;

const orgTypeLabels = {
  child: "Child",
  spinoff: "Parent (trial)",
  parent: "Parent",
};

type Props = {
  customer: Customer;
  fetchCustomer: () => Promise<void>;
  canEdit: boolean;
};

export default function ({ customer, fetchCustomer, canEdit }: Props) {
  const [ddOrgs, loading] = useAPIData<DatadogOrganization[]>(
    "/datadog/organizations",
    (data) => data.datadogOrganizations,
    []
  );
  const nameToOrg = Object.fromEntries(ddOrgs.map((org) => [org.name, org]));

  const customerOrgs = ddOrgs.filter((org) =>
    customer.toolsAndIntegrations?.datadogOrganizations?.includes(org.name)
  );

  const columns: ColumnProps<DatadogOrganization>[] = React.useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "name",
        Cell: ({ row }) => (
          <>
            <Text mb="4px">{row.original.name}</Text>
            <Text size="sm" mb={0}>
              {row.original.id}
            </Text>
          </>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ row }) => orgTypeLabels[row.original.type],
      },
      {
        Header: "Parent",
        accessor: "parent",
        Cell: ({ value }) => {
          const parentOrg = nameToOrg[value];

          return parentOrg ? (
            <>
              <Text mb="4px">{parentOrg.name}</Text>
              <Text size="sm" mb={0}>
                {parentOrg.id}
              </Text>
            </>
          ) : (
            "–"
          );
        },
      },
    ],
    [nameToOrg]
  );

  return (
    <div>
      <DetailRow title="Datadog Organizations">
        <DatadogOrgsSelect
          customer={customer}
          ddOrgs={ddOrgs}
          fetchCustomer={fetchCustomer}
          canEdit={canEdit}
        />
      </DetailRow>

      {!loading && (
        <TableBorder>
          <Table columns={columns} data={customerOrgs} mb="0" />
        </TableBorder>
      )}
    </div>
  );
}
